import React from "react"
import Classes from  './Service_Box.module.css'
import { Link } from "react-router-dom";
function Service_Box(props){
    return(
        <React.Fragment>
             <Link to={`/Tratamento/${props.Treatment}`} className={Classes.Service_Box}>
                <div className={Classes.Content}>
                        <section className={Classes.Title} style={{backgroundColor:props.bdcolor,color:props.color}}>
                            {props.Title}
                        </section>
                        <section className={Classes.History_Gallery}>
                    
                    <div className={Classes.Gallery_Images}>
                        <div class={Classes.inner}>
                        <img alt='' src={props.image}/>
                         </div>
                        

                    </div>
                    </section>
                </div>
            </Link>
        </React.Fragment>
    )
}


export default Service_Box