import React from "react";
import { Link } from "react-router-dom";
import Classes from './index.module.css';

function ImageBox(props){
    return(
        <React.Fragment>
            <Link to={`/Tratamento/${props.Treatment}`} className={Classes.ImageBox}>
                <img src={props.image} alt="Tratamento"/>
            </Link>
        </React.Fragment>
    )
}

export default ImageBox;
