import React from "react"
import Classes from './index.module.css'

import Service_Box from "../Components/Service_Box/Service_Box"

import Box1_img from '../../../assets/Home/services/box1.png'
import Box2_img from '../../../assets/Home/services/box2.png'
import Box3_img from '../../../assets/Home/services/Box3.jpg'
import Box4_img from '../../../assets/trataments/tratamentos/occluso_o_guide.jpg'
import tratament2 from '../../../assets/trataments/tratament2.jpg'
 function Home_Services(){
    return(
        <React.Fragment>
            <div className={Classes.Home_Services}>

                    <section className={Classes.MainBox}> 
                        <Service_Box Treatment={"Ortodontia"} Title={"Ortodontia"} bdcolor={'#466345'} color={'#ffffff'}image={Box1_img}/>
                        <Service_Box Treatment={"Harmonização"} Title={"Harmonização"} bdcolor={'#e9ead8'} color={'#124742'} image={Box3_img}/>
                        <Service_Box Treatment={"Alinhadores"} Title={"Alinhadores Estéticos"} bdcolor={'#af8c4c'} color={'#ffffff'} image={Box2_img}/>
                        <Service_Box Treatment={"Ortopedia"} Title={"Ortopedia Facial"} bdcolor={'#466345'} color={'#ffffff'}image={tratament2}/>
                    </section>

            </div>
        </React.Fragment>
       
    )
}


export default Home_Services