import React, { useState, useEffect } from "react";
import Classes from "./index.module.css";
import { connect } from "react-redux";

/*Images*/
import left_Man from "../../../assets/Home/Header/left_Man.png";
import right_Girl from "../../../assets/Home/Header/right_Woman.png";
import right_Girl2 from "../../../assets/Home/Header/right_Woman2.png";
import leftLogo from "../../../assets/Home/Header/leftLogo.png";
import rightLogo from "../../../assets/Home/Header/rightLogo.png";
import Banner from "../../../assets/Home/Header/recepção.jpg";
import Banner2 from "../../../assets/Home/Header/banner2.jpg";
export default function Home_Header() {
  const [FirstBanner, setFirstBanner] = useState(true);

  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeOut(true);
      setTimeout(() => {
        setFirstBanner(!FirstBanner);
        setFadeOut(false);
      }, 1000); // Durante 1s ocorre a transição de Fade Out e depois troca o Banner
    }, 10000); // Alterna o banner a cada 5s

    return () => clearInterval(interval); // Limpa o interval ao desmontar o componente
  }, [FirstBanner]);

  return (
    <React.Fragment>
      <header
        className={`${Classes.Home_Header} ${
          fadeOut ? Classes.fadeOut : Classes.fadeIn
        }`}
      >
        {FirstBanner ? (
          <>
            <section
              style={{ backgroundImage: `url(${Banner})` }}
              className={Classes.FirstLayer}
            >
              <div className={Classes.TextBox}>
                <div className={Classes.FirstLine}>Realce</div>

                <div className={Classes.lineBox}>
                  <div className={Classes.SecondLine}>a sua</div>

                  <div className={Classes.ThirdLine}>Beleza</div>
                </div>
              </div>
            </section>

            <section className={Classes.SecondLayer}>
              <img src={leftLogo} className={Classes.leftLogo} />
              <img className={Classes.Header_LeftBox} src={left_Man} />

              <img className={Classes.Header_RightBox} src={right_Girl} />
              <img src={rightLogo} className={Classes.rightLogo} />
            </section>
          </>
        ) : (
          <>
            <section
              style={{
                backgroundImage: `url(${Banner})`,
              }}
              className={Classes.FirstLayer}
            >
              <div className={Classes.TextBox}>
                <div className={Classes.FirstLine}>Sorria</div>

                <div className={Classes.lineBox}>
                  <div
                    style={{ fontFamily: "Philosopher regular" }}
                    className={Classes.SecondLine}
                  >
                    para a
                  </div>

                  <div
                    style={{ textAlign: "center" }}
                    className={Classes.ThirdLine}
                  >
                    Vida
                  </div>
                </div>

                <div className={Classes.Subtitle}>
                  <div className={Classes.Subtitle_First}>Conheça nossos</div>

                  <div className={Classes.Subtitle_Second}>
                    tratamentos odontológicos
                  </div>
                </div>
              </div>
            </section>

            <section className={Classes.SecondLayer}>
              <img src={leftLogo} className={Classes.leftLogo} />

              <img className={Classes.Header_RightBox} src={right_Girl2} />
              <img src={rightLogo} className={Classes.rightLogo} />
            </section>
          </>
        )}
      </header>
    </React.Fragment>
  );
}
