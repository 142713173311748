import React from "react";
import Classes from "./index.module.css";

function NewsBox_Content(props) {
  const handleOnClick = () => {
    props.navigate(`/Blog/${props.Data.Param}`);
  };
  return (
    <React.Fragment>
      <div className={Classes.Box_Elements}>
        <section className={Classes.Blog_Box_Header}>
          {props.Data.Title}
        </section>

        <section className={Classes.Blog_Box_Content}>
          <p>{props.Data.Content}</p>
        </section>

        <section className={Classes.Blog_Box_Footer}>
          <section className={Classes.ButtonArea}>
            <div
              className={Classes.Button}
              onClick={() => {
                props.ChangePage();
              }}
            >
              &lt;
            </div>
            <div
              className={Classes.Button}
              onClick={() => {
                props.ChangePage(1);
              }}
            >
              &gt;
            </div>
            <div className={Classes.Button} onClick={handleOnClick}>
              +
            </div>
          </section>
          <section className={Classes.ContentLink} onClick={handleOnClick}>
            <p>Saiba mais</p>
          </section>
        </section>
      </div>
    </React.Fragment>
  );
}

export default NewsBox_Content;
