import React, { useState } from "react";
import Classes from "./index.module.css";

import Plant_img from "../../../assets/Home/services/green_plant.png";

import BlogImg from "../../../assets/Home/services/BlogImg.jpg";
import SkinCareImg from "../../../assets/Home/services/Skincare.jpg";
import TeethCareImg from "../../../assets/Home/services/teethcare.png";
import FlossImg from "../../../assets/Home/services/floss.jpg";

import NewsBox_Content from "../Components/NewsBox_Content";
function Footer_Blog({ navigate }) {
  const [Page, setPage] = useState(0);

  const Content = [
    {
      Image: BlogImg,
      Title: "SAIBA OS BENEFÍCIOS DA TOXINA BOTULÍNICA",
      Content:
        "Trata-se de um procedimento seguro, não invasivo e com ótimos resultados...",
      Param: "toxina-botulinica",
    },
    {
      Image: SkinCareImg,
      Title: "Cuidados com a pele depois de um procedimento facial",
      Param: "procedimento-facial",
      Content:
        "Os cuidados com a pele após um procedimento facial são essenciais para promover a recuperação adequada e garantir resultados satisfatórios...",
    },
    {
      Image: TeethCareImg,
      Title: "8 dicas de como combater o mau hálito",
      Param: "mau-halito",
      Content: "Para combater o mau hálito, siga estas oito dicas simples...",
    },
    {
      Image: FlossImg,
      Title: "Usar fio dental é fundamental",
      Param: "fio-dental",
      Content:
        "O uso do fio dental é fundamental para manter uma boa saúde bucal e combater o mau hálito...",
    },
  ];

  function ChangePage(action) {
    switch (action) {
      case 1:
        if (Page + 1 > Content.length - 1) {
          setPage(0);
        } else {
          setPage((prevState) => {
            return prevState + 1;
          });
        }
        break;

      default:
        if (Page - 1 < 0) {
          setPage(0);
        } else {
          setPage(Page - 1);
        }
    }
  }
  return (
    <React.Fragment>
      <div className={Classes.Home_Blog}>
        <div className={Classes.Home_Blog_Content}>
          <section className={Classes.Plant_Section}>
            <img className={Classes.Plant_Img} src={Plant_img} />
          </section>

          <section className={Classes.Blog_Logo}>
            <p>Mural</p>
          </section>

          <section className={Classes.NewBox_Area}>
            <div className={Classes.Box}>
              <NewsBox_Content
                Data={Content[Page]}
                navigate={navigate}
                ChangePage={ChangePage}
              />
            </div>
          </section>
        </div>

        <section className={Classes.ImageArea}>
          <img src={Content[Page].Image} />

          <div className={Classes.LayerDecoration}></div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Footer_Blog;
