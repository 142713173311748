import React from "react";
import Classes from "./index.module.css";
import { Carousel } from "react-bootstrap";
import Institute1 from "../../assets/institute/instituto.png";
import Institute2 from "../../assets/institute/institute2.jpg";
import Institute3 from "../../assets/institute/institute3.jpg";
import Institute4 from "../../assets/institute/institute4.jpg";
import instituto5 from "../../assets/institute/instituto2.jpeg";
import instituto6 from "../../assets/institute/instituto3.jpg";
import instituto7 from "../../assets/institute/instituto4.jpeg";
import comments from "../../assets/institute/comments.jpeg";
import comments2 from "../../assets/institute/comments2.jpeg";
function Institute() {
  const images = [instituto5, instituto6, instituto7];
  const images2 = [comments, comments2];
  return (
    <React.Fragment>
      <div className={Classes.Institute_Box}>
        <section className={Classes.Institute}>
          <section className={Classes.Institute_Content}>
            <div className={Classes.Institute_Content_Header}>
              <p>História</p>
            </div>

            <div className={Classes.Institute_Content_Text}>
              <p>
                O Instituto Dinâmica da Face surgiu há 23 anos, inicialmente
                como um consultório odontológico de atendimento exclusivo da
                Dra. Maria Carolina. Hoje estamos escrevendo um novo capítulo da
                nossa história. O consultório está se transformando no Instituto
                Dinâmica da Face, uma clínica multidisciplinar e integrada para
                tratamentos odontológicos e do desenvolvimento completo da face.
              </p>

              <p>
                Nosso compromisso é constante com a excelência em nossos
                atendimentos, buscando um tratamento humanizado e
                individualizado a todos os pacientes.
              </p>

              <p>
                Utilizamos as técnicas mais modernas e seguras da Odontologia a
                fim de proporcionar qualidade de vida, saúde e bem-estar a todas
                as pessoas que sonham com um sorriso belo e harmônico.
              </p>
            </div>
          </section>

          <Carousel
            interval={3000}
            className={Classes.Carousel}
            indicators={false}
          >
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className={`d-block w-100 ${Classes.carousel_item}`}
                  src={image}
                  alt="Institute slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </section>

        <section className={Classes.Institute}>
          <section className={Classes.Institute_Content}>
            <div className={Classes.Institute_Content_Header}>
              <p>Nossa Missão</p>
            </div>

            <div
              style={{ paddingTop: "1vw" }}
              className={Classes.Institute_Content_Text}
            >
              <p>
                Nossa missão é o que fazemos juntos! Proporcionar a você uma
                experiência agradável e confortável, em um ambiente acolhedor,
                sempre ouvindo suas necessidades e expectativas, para que
                possamos desenvolver um plano de tratamento personalizado e que
                atenda às suas necessidades e de toda a sua família.
              </p>
            </div>
          </section>
          {/*
                    <section className={Classes.History_Gallery}>
                    
                    <div className={Classes.Gallery_Images}>
                        <div class={Classes.inner}>
                        <img alt='' src={Institute2}/>
                         </div>
                        

                    </div>

                    <div className={Classes.Gallery_Images}>

                    <div class={Classes.inner}>
                        <img alt='' src={Institute3}/>
                         </div>

                    </div>

                    <div className={Classes.Gallery_Images}>

                    <div class={Classes.inner}>
                        <img alt='' src={Institute4}/>
                         </div>
                    </div>

                </section>
                    */}
          <Carousel interval={5000} className={Classes.Carousel}>
            {images2.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className={`d-block w-100 ${Classes.carousel_item}`}
                  src={image}
                  alt="Institute slide"
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Institute;
