import React from "react";
import Classes from './index.module.css'


function ProfileBox(props){
    return(
        <React.Fragment>
            <div className={Classes.ProfileBox}>
                
            <div className={Classes.Gallery_Images}>


               
                        <img alt='' src={props.image}/>
                
               
                    
            </div>


                <section className={Classes.ProfileBox_Content}>
                
                <div className={Classes.ProfileBox_Content_Name}>
                {props.Name}
                </div>

                <div className={Classes.ProfileBox_Content_Title}>
                {props.Function}
                </div>

                <div className={Classes.ProfileBox_Content_CRO}>
                <p>CRO SP: {props.CRO}</p>
                </div>
                
                <div className={Classes.ProfileBox_Content_Title}>
                {props.Title}
                </div>

                <div className={Classes.ProfileBox_Content_Text}>
                {props.content}
                </div>

                </section>

            </div>
        </React.Fragment>
    )
}


export default ProfileBox