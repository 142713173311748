import React from 'react'
import Classes from './index.module.css'

import tratament1 from '../../assets/trataments/tratament1.jpg'
import tratament2 from '../../assets/trataments/tratamentos/2759.JPG'
import tratament3 from '../../assets/trataments/tratament3.jpg'
import tratament4 from '../../assets/trataments/tratament4.jpg'
import tratament5 from '../../assets/trataments/tratament5.jpg'
import tratament6 from '../../assets/trataments/tratament6.jpg'
import tratament7 from '../../assets/trataments/tratament7.jpg'
import tratament8 from '../../assets/trataments/tratament8.jpg'

import ImageBox from '../../components/Treatments/ImageBox'
function Treatments(){
    return(
        <React.Fragment>
           
            <header className={Classes.Treatments_Header}>
                <p>Tratamentos</p>
            </header>
            <div className={Classes.Treatments_Box}>
            <div>
            <section className={Classes.Treatments_Row}>
                <ImageBox Treatment='Harmonização' image={tratament1}/>
                <ImageBox Treatment='Ortodontia' image={tratament5}/>
                <ImageBox Treatment='Alinhadores' image={tratament8}/>
                <ImageBox Treatment='Odontopediatria' image={tratament4}/>
            </section>

            <section className={Classes.Treatments_Label}>
                <label>Harmonização</label>
                <label>Ortodontia</label>
                <label>Alinhadores Estéticos <br />Invisalign </label>
                <label>Odontopediatria</label>
            </section>
            </div>

            <div>
            <section className={Classes.Treatments_Row}>
                <ImageBox Treatment='Clareamento' image={tratament6}/>
                <ImageBox Treatment='Facetas' image={tratament3}/>
                <ImageBox Treatment='Cirurgia' image={tratament7}/>
                <ImageBox Treatment='Ortopedia' image={tratament2}/>
            </section>

            <section className={Classes.Treatments_Label}>
                <label>Clareamento</label>
                <label>Facetas e Lentes <br /> de Contato </label>
                <label>Cirurgia</label>
                <label>Ortopedia Funcional</label>
            </section>
            </div>
            </div>
    
            
            
        </React.Fragment>
    )
}


export default Treatments