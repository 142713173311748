import React, { useState, useEffect } from "react";
import Classes from "./index.module.css";

import Logo_img from "../../../assets/Global/logo.png";
import Google_Safe_img from "../../../assets/Global/Google_Safe.png";
import shield from "../../../assets/Global/shield.png";
import wpp from "../../../assets/Global/wpp.png";
import { useNavigate } from "react-router-dom";
import Footer_Blog from "../../Home/Blog";
import Cookies from "js-cookie";
function Footer() {
  const [CookiesTerms, setCookiesTerms] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    // Verificar se o cookie 'acceptCookies' existe
    const acceptCookies = Cookies.get("acceptCookies");
    if (acceptCookies) {
      setCookiesTerms(false); // Esconde a barra de cookies se o cookie existir
    }
  }, []);

  const handleAcceptCookies = () => {
    // Definir o cookie 'acceptCookies' para 'true'
    Cookies.set("acceptCookies", "true", { expires: 365 }); // Cookie expira em 1 ano
    setCookiesTerms(false); // Esconde a barra de cookies
  };

  return (
    <React.Fragment>
      <Footer_Blog navigate={navigate} />

      <footer className={Classes.PageFooter}>
        <section className={Classes.PageFooter_InfoData}>
          <img src={Logo_img} className={Classes.Logo_img} />

          <table>
            <thead>
              <tr>
                <th>Contato</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Ed.Mondrian Tower</td>
              </tr>

              <tr>
                <td>Av. Washington Luiz, 685 - 2º andar sala 22</td>
              </tr>

              <tr>
                <td>Jardim Emilia, Sorocaba - SP</td>
              </tr>

              <tr>
                <td>Tel:&#40;15&#41;3233.3564 | &#40;15&#41; 98115.7932 </td>
              </tr>
            </tbody>
          </table>

          <section className={Classes.IconImgs}>
            <div className={Classes.LicenseICons}>
              <img className={Classes.GoogleIcon} src={Google_Safe_img} />

              <div className={Classes.shieldIcon}>
                <img src={shield} />
                <p>
                  você esta em um <br />
                  <div style={{ fontWeight: "bold", color: "#009200" }}>
                    {" "}
                    SITE SEGURO
                  </div>
                </p>
              </div>
            </div>
            <a
              href="https://contate.me/institutodinamicadaface"
              className={Classes.Wpp_ICon}
            >
              <img src={wpp} className={Classes.Wpp_ICon}></img>
            </a>
          </section>
        </section>
        {CookiesTerms && (
          <section className={Classes.CookiesTerms}>
            <p>
              Utilizamos cookies para melhorar o desempenho e a sua experiência
              ao utilizar nosso site. Nós procuramos, assim, explicar de forma
              <br />
              transparente como, quando e porque utilizamos cookies. Ao acessar
              o nosso site, você concorda com a política de cookies e
              privacidade.
            </p>

            <button
              onClick={handleAcceptCookies}
              className={Classes.AgreeButton}
            >
              CONCORDO
            </button>
          </section>
        )}

        <section className={Classes.Statement}>
          <p>DIREITOS RESERVADOS A INSTITUTO DINÂMICA DA FACE</p>
        </section>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
