import React from "react";
import Classes from './index.module.css'
import { Link } from 'react-router-dom';
import FirstBox from '../../assets/team/doutora.jpeg'
import SecondBox from '../../assets/team/mauricio.jpg'

import ProfileBox from "../../components/Team/ProfileBox";


function Team(){



    const User1 =<><p>Sou a Maria Carolina. Tenho 23 anos de formada, especialista em Ortodontia e Ortopedia facial desde 2005, com foco em aumentar meus conhecimentos na área, em 2015 realizei um aperfeiçoamento em técnicas e aparelhos mais modernos em Ortopedia Facial. Já em 2021, concluí minha Especialização em Harmonização Orofacial.</p> <p> Sou filha de dentista, minha mãe foi a maior incentivadora e motivadora em seguir a sua profissão. Sempre foi meu sonho atuar na saúde dos dentes e hoje em dia posso dizer que meu foco é um atendimento totalmente voltado para o bem-estar geral e cuidado como um todo. Sempre abordo sobre higienização, alimentação, necessidade de nos cuidarmos dia a dia, exercícios físicos, cuidados com envelhecimento da arcada e da face. </p><p>Gosto quando os pacientes saem do consultório sempre melhores de como entraram, tanto na saúde como na energia também! Meus cuidados vão além do sorriso e da face, sempre busco trazer a realização e felicidade para meus pacientes. Paciência, persistência e disciplina são fatores essenciais para minha profissão e para a minha vida.</p></>
    return(
        <React.Fragment>
            <header className={Classes.Team_Header}>
                    <p className={Classes.Team_Header_Title}>Equipe</p>

                    <p className={Classes.Team_Header_SubTitle}>
                    Possuímos uma equipe de profissionais especializados, altamente qualificados e experientes para fornecer os melhores tratamentos e atender às suas necessidades.
                    </p>
            </header>
            <div className={Classes.Team_Box}>
                <ProfileBox image={FirstBox} Name={<p>Dra. Maria Carolina <br />Bassul Sacchi</p>} CRO={'70.289'} Title={<p>Minha História:</p>} Function={<><p>Diretora clínica/cirurgiã-dentista</p></>} content={User1}/>
            {/*
                <ProfileBox image={SecondBox} Name={<p>Dr. Mauricio <br /> Dal Moro</p>} CRO={'97.401'}/>

                <ProfileBox image={FirstBox} Name={<p>Dra. Andrea</p>} CRO={'00.000'}/>
    */}

                <Link to="/Conectar">
                <section className={Classes.AppointmentButton}>
                    <p>Agende sua avaliação</p>
                </section>
            </Link>

            </div>
        </React.Fragment>
    )
}

export default Team