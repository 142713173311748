import React from "react";
import Classes from './Home.module.css'
import { connect } from "react-redux";

/*Images*/
import Home_Header from "../../components/Home/Header";
import Home_Services from "../../components/Home/Home_Services";

function Home(){
 
    return(
        

            <React.Fragment>
                
            <section className={Classes.Home_Box}>


                <Home_Header />

                <Home_Services />

                
            
             </section>

             </React.Fragment>
            
           
        
    )
}

export default connect(state=>({PageState:state.PageState}))(Home)