import React from "react";
import Classes from './index.module.css'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import TreatmentBox from "../../components/Team/TreatmentBox";

// Ortodontia
import autoligavel from '../../assets/trataments/tratamentos/autoligável_estetico.jpg'
import imgAparelhoMetalico from '../../assets/trataments/tratamentos/aparelho_metálico.jpg';
import lingual from '../../assets/trataments/tratamentos/lingual.jpg'
// Harmonização
import imgAplicacao from '../../assets/trataments/tratamentos/aplicação.jpg';
import imgBoca from '../../assets/trataments/tratamentos/boca.jpg';
import labial from '../../assets/trataments/tratamentos/labial.jpg'
import estimuladores from '../../assets/trataments/tratamentos/bioestimuladores.jpg'
import fiosImg from '../../assets/trataments/tratamentos/fios.jpg'
// Alinhadores
import alinhador from '../../assets/trataments/tratamentos/alinhador.jpg';
import imgConsultorio315 from '../../assets/trataments/tratamentos/consultorio_315.JPG';
// Odontopediatria
import kid from '../../assets/trataments/tratamentos/kid.jpg'
// Clareamento
import caseiro from '../../assets/trataments/tratamentos/clareamento_caseiro.jpg'
import caseiro2 from '../../assets/trataments/tratamentos/caseiro.png'
import imgMarreiraGengival from '../../assets/trataments/tratamentos/marreira_gengival.jpg';
import imgOcclusoOGuide from '../../assets/trataments/tratamentos/occluso_o_guide.jpg';
import consultorio from '../../assets/trataments/tratamentos/consultorio.jpg'
import alinhador2 from '../../assets/trataments/tratamentos/alinhador2.jpg'
import bracelets from '../../assets/trataments/tratamentos/486.JPG'
import microagulhamento from '../../assets/trataments/tratamentos/microagulhamento.jpg'
// Facetas
import lentes from '../../assets/trataments/tratamentos/lentes.jpg'
import facetas from '../../assets/trataments/tratamentos/facetas.jpg'
import imgOrtopedia1 from '../../assets/trataments/tratamentos/Ortopedia1.JPG';
// Cirurgia
import siso from '../../assets/trataments/tratamentos/siso.jpg'
import cirurgiaImg from '../../assets/trataments/tratamentos/cirurgiaImg.jpg'
function TreatmentPage(){

    const { treatment } = useParams(); // Obtém o nome do tratamento da URL
   
    switch (treatment) {
        case "Ortodontia":
            return(
                <React.Fragment>
        
                   
                    <header className={Classes.Team_Header}>
                            <p className={Classes.Team_Header_Title}>{treatment}</p>
        
                            <p className={Classes.Team_Header_SubTitle}>
                            Ortodontia é a especialidade odontológica responsável pela correção do alinhamento dentário.<br />
                            Diversas são as opções de aparelhos disponíveis para o tratamento ortodôntico, são eles:    
                            </p>
                        </header>
                    <div className={Classes.Team_Box}>
                    <TreatmentBox image={imgAparelhoMetalico} Name={<p>Aparelho metálico</p>} content={
                    <p>Sem dúvida, o modelo mais convencional e conhecido pela grande maioria das pessoas.<br />
                     Pode ser usado para tratar diversos problemas de desalinhamento dental.</p>}/>
                    <TreatmentBox image={imgConsultorio315} Name={<p>Aparelhos estéticos</p>} content={
                    <p>Este modelo de aparelho também é muito eficiente, podendo ser de materiais como porcelana ou safira, tornando as peças muito mais estéticas e imperceptíveis.</p>}/>
                    <TreatmentBox image={autoligavel} Name={<p>Aparelho autoligável</p>}  content={
                    <p>O sistema de aparelho autoligável é uma evolução dos aparelhos fixos. Adaptado tanto à versão tradicional quanto à estética, os bráquetes não precisam das borrachas para prender os fios ortodônticos. Ele é preso por uma “portinha” existente no bráquete. Este aparelho pode favorecer a redução do tempo de tratamento, bem como aumentar o intervalo da realização das manutenções.</p>}/>
                    <TreatmentBox image={lingual} Name={<p>Aparelho lingual</p>} content={<p>Esta é outra modalidade de aparelho que pode ser considerada pelos pacientes que buscam um tratamento discreto. Com este tipo de aparelho temos os bráquetes e fios metálicos como no modelo tradicional, a diferença é que eles ficam localizados atrás dos dentes.</p>}/>
                    <TreatmentBox alt={'https://br.freepik.com/fotos-gratis/mulher-usando-aparelho-invisivel-de-silicone-ortodontico_8897015.htm#&position=1&from_view=search&track=ais'} image={alinhador2} Name={<p>Alinhadores estéticos</p>} content={<p>Este modelo de aparelho é uma das modalidades de tratamento ortodôntico mais procurados da atualidade. Por se tratar de placas transparentes que permite o alinhamento dos dentes sem a necessidade de fazer uso do aparelho ortodôntico tradicional, este modelo proporciona flexibilidade no tratamento atrelado a uma estética imperceptível.
Uma das marcas mais eficientes e conhecidas com a qual trabalhamos é o Invisalign. É o sistema que tem a melhor tecnologia para movimentar os dentes e solucionar a maioria dos problemas ortodônticos.
</p>}/>
                   
                    <Link to="/Conectar">
                                    <section className={Classes.AppointmentButton}>
                                        <p>Agende sua avaliação</p>
                                    </section>
                    </Link>
                    </div>
                </React.Fragment>
            )
        
            break;

            case "Harmonização":
            return(
                <React.Fragment>
        
                   
                    <header className={Classes.Team_Header}>
                            <p className={Classes.Team_Header_Title}>{treatment}</p>
        
                            <p className={Classes.Team_Header_SubTitle}>
                            É uma especialidade que busca alcançar a harmonia estética e funcional da face e do sorriso. Nossa área de atuação inclui procedimentos como toxina botulínica, preenchimentos faciais, bioestimuladores de colágeno, uso de fios e cirurgias como a bichectomia.
                    Com a harmonização orofacial, é possível obter um equilíbrio estético e funcional da face, valorizando a identidade de cada paciente e proporcionando mais autoconfiança e bem-estar.
                            </p>
                        </header>
                    <div className={Classes.Team_Box}>
                    <TreatmentBox image={labial} Name={<p>Preenchimento labial</p>} content={
                    <p> Os lábios são uma característica proeminente no rosto e seu formato é fundamental para uma aparência bonita e harmoniosa. Por isso, muitas pessoas se sentem inseguras em relação ao contorno ou volume dos lábios. Para aqueles que desejam melhorar essas características, o preenchimento labial com ácido hialurônico pode ser uma ótima opção de tratamento. Além do volume, o procedimento também pode realçar o contorno dos lábios, deixando-os mais atraentes. É importante escolher um produto desenvolvido especificamente para a região, que preserve os movimentos funcionais dos lábios durante o processo.</p>}/>
                    <TreatmentBox image={imgBoca} Name={<p>Preenchimento de "Bigode chinês"</p>} content={
                    <p>A causa da formação do “bigode chinês” está na perda de sustentação na região do malar, o que resulta na descida da gordura facial e na flacidez da pele. Para obter resultados mais naturais, contamos com diversos procedimentos que podem ajudar nesses casos, incluindo pontos de sustentação e preenchimento com ácido hialurônico. Bioestimuladores de colágeno, Ultrassom microfocado e fios de PDO também contribuem para um ótimo resultado.</p>}/>
                    <TreatmentBox image={imgAplicacao} Name={<p>BOTOX</p>}  content={
                    <p>Trata-se de um procedimento seguro, não invasivo e com ótimos resultados. Por isso a toxina botulínica mais conhecida pelo nome da sua marca mais famosa o Botox® tornou-se tão popular. É responsável em diminuir a contração dos músculos da face amenizando rugas de expressão como pés de galinha, linhas da testa e rugas no centro dos olhos. </p>}/>
                    <TreatmentBox image={estimuladores} Name={<p>Bioestimulantes de colágeno</p>}  content={
                    <p>São substâncias sintéticas biocompatíveis que são completamente absorvidas pelo organismo. Eles são injetados na derme profunda e estimulam a produção natural de colágeno, proporcionando uma aparência mais jovem e natural. Os bioestimuladores têm excelentes resultados no tratamento de flacidez, ptose facial e lipoatrofia, condições frequentemente observadas em atletas de alta performance, pacientes que perderam peso rapidamente e pessoas em processo natural de envelhecimento.</p>}/>
                     <TreatmentBox image={fiosImg} Name={<p>Fios de PDO</p>}  content={
                    <p>Os fios de PDO, também conhecidos como Polidioxanona, são um material sintético absorvível e biocompatível capaz de estimular a formação de novo colágeno na pele, melhorando sua qualidade e espessura. Dependendo das características do fio, pode ajudar no tratamento da flacidez localizada, no preenchimento de sulcos ou até no reposicionamento de estruturas da face, produzindo um efeito lifting não cirúrgico.</p>}/>
                    <TreatmentBox alt={"https://br.freepik.com/fotos-gratis/medico-com-luvas-de-borracha-fazendo-uma-cirurgia-plastica-no-rosto-em-uma-jovem-feliz_17073268.htm#query=microagulhamento&position=1&from_view=search&track=sph"} image={microagulhamento} Name={<p>Microagulhamento</p>}  content={
                    <p>O microagulhamento é uma técnica que tem como objetivo estimular a produção de colágeno na pele do rosto e pescoço. Conhecido também como indução percutânea de colágeno por agulhas, consiste na aplicação de diversas agulhas que perfuram as camadas da pele, causando microferimentos e vermelhidão, estimulando naturalmente a regeneração da pele.</p>}/>
                   
                    <Link to="/Conectar">
                        <section className={Classes.AppointmentButton}>
                            <p>Agende sua avaliação</p>
                        </section>
                    </Link>

                    </div>
                </React.Fragment>
            )
        
            break;


            case "Alinhadores":
                return(
                    <React.Fragment>
            
                       
                        <header className={Classes.Team_Header}>
                                <p className={Classes.Team_Header_Title}>ALINHADORES INVISALIGN</p>
            
                                <p className={Classes.Team_Header_SubTitle}>
                                Uma das marcas mais eficientes e conhecidas com a qual trabalhamos é o Invisalign®. É o sistema que tem a melhor tecnologia para movimentar os dentes e solucionar a maioria dos problemas ortodônticos.</p>
                            </header>
                        <div className={Classes.Team_Box}>
                        <TreatmentBox image={alinhador} Name={<p>Alinhador</p>} content={
                        <p>Tratamento inovador , usando placas transparentes para fazer a movimentação dos dentes ajustando sua mordida de forma mais sutil durante o tratamento.</p>}/>
                        
                        <Link to="/Conectar">
                            <section className={Classes.AppointmentButton}>
                                <p>Agende sua avaliação</p>
                            </section>
                        </Link>
                        </div>
                    </React.Fragment>
                )
            
                break;

                case "Odontopediatria":
                    return(
                        <React.Fragment>
                
                           
                            <header className={Classes.Team_Header}>
                                    <p className={Classes.Team_Header_Title}>{treatment}</p>
                
                                    <p className={Classes.Team_Header_SubTitle}>
                                    A odontopediatria é uma especialidade odontológica voltada para o cuidado da saúde bucal das crianças. Os odontopediatras possuem conhecimentos específicos para lidar com as particularidades do desenvolvimento oral infantil, oferecendo prevenção, tratamento e educação em saúde bucal, visando garantir um sorriso saudável e o bem-estar.</p>
                                </header>
                            <div className={Classes.Team_Box}>
                            <TreatmentBox image={kid} Name={<p>Acompanhamento</p>} content={
                            <p>O acompanhamento odontopediátrico é um elemento fundamental para garantir a saúde bucal das crianças ao longo do tempo. Esse processo consiste em consultas regulares com o odontopediatra, que acompanha de perto o desenvolvimento da dentição e realiza avaliações periódicas.</p>}/>
                            
                            <Link to="/Conectar">
                                <section className={Classes.AppointmentButton}>
                                    <p>Agende sua avaliação</p>
                                </section>
                            </Link>
                            </div>
                        </React.Fragment>
                    )
                
                    break;

                    
                case "Facetas":
                    return(
                        <React.Fragment>
                
                           
                            <header className={Classes.Team_Header}>
                                    <p className={Classes.Team_Header_Title}>Facetas e Lentes de contato</p>
                
                                  
                                </header>
                            <div className={Classes.Team_Box}>
                            <TreatmentBox alt={"https://br.freepik.com/fotos-gratis/mulher-bonita-e-bonita-europeia-mantem-ambas-as-maos-no-rosto-sorrisos-amplamente-vestida-com-camisa-branca-oculos-transparentes-ouve-noticias-agradaveis-sobre-um-fundo-azul-parece-fofa-para-a-camera_21702768.htm#query=lentes%20dentarias&position=2&from_view=search&track=ais"} image={lentes} Name={<p>Lentes de Contato</p>} content={
                            <p>Atualmente um dos serviços mais procurados são as lentes de contato dental que são películas de porcelana ou resina altamente resistentes, projetadas para cobrir imperfeições dentárias, proporcionando um sorriso perfeito, sem dor e sem sensibilidade. As lentes permitem a correção de cor, forma, posição e espaços que possam existir entre os dentes.</p>}/>
                             <TreatmentBox alt={"https://br.freepik.com/fotos-gratis/homem-sorrindo-enquanto-dentista-feminina-mantendo-gama-de-obturacoes_26824848.htm#query=facetas&position=1&from_view=keyword&track=sph"} image={facetas} Name={<p>Facetas</p>} content={
                            <p>As facetas dentárias são finas lâminas de porcelana ou resina composta que são colocadas sobre a superfície frontal dos dentes para melhorar sua aparência estética. Esse procedimento odontológico é utilizado para corrigir imperfeições como manchas, descolorações, desalinhamentos leves, espaçamentos e irregularidades nos dentes.</p>}/>
                          
                          <Link to="/Conectar">
                                <section className={Classes.AppointmentButton}>
                                    <p>Agende sua avaliação</p>
                                </section>
                            </Link>
                            </div>
                        </React.Fragment>
                    )
                
                    break;


                    case "Clareamento":
                        return(
                            <React.Fragment>
                    
                               
                                <header className={Classes.Team_Header}>
                                        <p className={Classes.Team_Header_Title}>{treatment}</p>
                    
                                        <p className={Classes.Team_Header_SubTitle}>
                                        O clareamento dental é um processo que visa retirar os pigmentos do esmalte e da dentina dos dentes, deixando-os mais brancos e brilhantes. Existem três tipos de clareamento disponíveis:
                                       </p>
                                    </header>
                                <div className={Classes.Team_Box}>
                                <TreatmentBox alt={"https://br.freepik.com/fotos-gratis/close-up-tiro-de-mulher-de-cabelo-encaracolado-remove-comida-usa-fio-ou-fio-dental-pisca-olho-mantem-a-boca-aberta-cuida-de-sua-cavidade-bucal-poses-interior-uso-do-fio-dental-e-conceito-de-higiene-oral_28946437.htm#query=clareamento%20dental%20caseiro&position=11&from_view=search&track=ais"} image={caseiro2} Name={<p>Caseiro</p>} content={
                                <p>Este método é realizado em casa, mas sempre com o acompanhamento do dentista. Antes do início do tratamento, o paciente deve passar por algumas consultas para avaliar sua saúde bucal e planejar o tratamento. É necessário confeccionar uma moldeira que será utilizada durante o procedimento.</p>}/>
                                 <TreatmentBox alt={"https://br.freepik.com/fotos-gratis/paciente-mulher-no-dentista_2534121.htm#query=clareamento%20dental&position=13&from_view=search&track=ais"} image={consultorio} Name={<p>Consultório</p>} content={
                                <p>Neste método, o clareamento é realizado no consultório do dentista. É necessário agendar uma série de sessões, que geralmente variam de uma a três, para obter os resultados desejados. Este procedimento não compromete a saúde bucal e produz um resultado natural.</p>}/>
                                 <TreatmentBox image={imgMarreiraGengival} Name={<p>Misto</p>} content={
                                <p>Este método é uma combinação dos dois tipos anteriores, e visa obter o melhor resultado possível de forma confortável e eficiente.</p>}/>
                                
                                <Link to="/Conectar">
                                    <section className={Classes.AppointmentButton}>
                                        <p>Agende sua avaliação</p>
                                    </section>
                                </Link>
                                </div>
                            </React.Fragment>
                        )
                    
                        break;


                        case "Cirurgia":
                            return(
                                <React.Fragment>
                        
                                   
                                    <header className={Classes.Team_Header}>
                                            <p className={Classes.Team_Header_Title}>{treatment}</p>
                        
                                            <p className={Classes.Team_Header_SubTitle}>
                                                Procedimentos cirúrgicos dentários podem ser necessários em diversos casos, e entre eles destacamos a frenectomia (remoção do freio labial), a gengivoplastia (cirurgia da gengiva) e a extração dos dentes do siso. Além desses, os implantes dentários são uma opção de tratamento de reabilitação oral e estética dental, indicados para pessoas que perderam dentes e necessitam de uma prótese fixa.
                                           </p>
                                        </header>
                                    <div className={Classes.Team_Box}>
                                    <TreatmentBox image={cirurgiaImg} Name={<p>Gengivoplastia</p>} content={
                                    <p>A gengivoplastia é uma cirurgia plástica realizada no tecido gengival, visando corrigir e modificar o formato das gengivas, promovendo seu alinhamento e harmonização com o rosto. Com isso, é possível deixar as bordas da gengiva mais arredondadas, simétricas e valorizar a aparência e formato dos dentes. Além disso, a gengivoplastia tem um propósito funcional importante, já que a gengiva é responsável por fixar os dentes no maxilar e mandíbula, dando suporte à arcada dentária e permitindo a mastigação, mordida e fala.</p>}/>
                        
                                     <TreatmentBox alt={"https://www.freepik.com/free-photo/man-touching-cheek-grimacing-from-pain-from-toothache_9584107.htm#query=frenectomia&position=2&from_view=search&track=ais"} image={siso} Name={<p>Extração dos dentes do siso</p>} content={
                                    <p>A extração do dente do siso, também conhecido como terceiro molar, é um procedimento comum na odontologia. Esses dentes geralmente erupcionam na cavidade bucal entre os 17 e 25 anos, e muitas vezes não há espaço suficiente para acomodá-los corretamente. Isso pode levar a diversos problemas, como impactação, dor, inflamação das gengivas e desalinhamento dos dentes adjacentes.</p>}/>
                                    
                                     
                                    <Link to="/Conectar">
                                        <section className={Classes.AppointmentButton}>
                                            <p>Agende sua avaliação</p>
                                        </section>
                                    </Link>
                                    </div>
                                </React.Fragment>
                            )
                        
                            break;
                        
    
        default:
            return(
                <React.Fragment>

           
            <header className={Classes.Team_Header}>
                    <p className={Classes.Team_Header_Title}>Ortopedia funcional</p>

                    <p className={Classes.Team_Header_SubTitle}>
                    Ortopedia Funcional dos Maxilares é a especialidade odontológica responsável por solucionar desequilíbrios ósseos, musculares e funcionais. Principalmente em crianças que estão na fase ativa de crescimento. Normalmente são utilizados aparelhos móveis adaptados e confeccionados especialmente para cada paciente.
                  </p>
                </header>
            <div className={Classes.Team_Box}>
            <TreatmentBox image={bracelets} Name={<p>Aparelho expansor palatino</p>} content={
                                    <p>Esse dispositivo é colocado na parte superior da boca e possui parafusos que podem ser ativados para promover a expansão gradual do palato (céu da boca). Essa expansão ajuda a corrigir problemas de apinhamento dos dentes, melhorar a respiração nasal e permitir um melhor encaixe das arcadas dentárias.</p>}/>

               <TreatmentBox image={imgOcclusoOGuide} Name={<p>Orthoghia</p>} content={
                                    <p>  Trabalhamos também com o Sistema Orthoghia, um de aparelhos miofuncionais que também alinham os dentes. Podemos dizer que são aparelhos móveis confortáveis que são alinhadores funcionais. Ao mesmo tempo que os dentes seguem com a erupção normal, conseguimos trabalhar nas crianças os problemas de função e hábitos.</p>}/>
                                   
                                   <Link to="/Conectar">
                                        <section className={Classes.AppointmentButton}>
                                            <p>Agende sua avaliação</p>
                                        </section>
                                    </Link>
            </div>
        </React.Fragment>
            )

            break;
    }
   
}

export default TreatmentPage