import React, { useState } from "react";
import Classes from "./index.module.css";

import wpp from "../../assets/Conectar/Icons/wpp.png";
import insta from "../../assets/Conectar/Icons/insta.png";
import emailIcon from "../../assets/Conectar/Icons/email.png";
import linkedin from "../../assets/Conectar/Icons/linkedin.png";

function Connect() {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [titulo, setTitulo] = useState("");
  const [mensagem, setMensagem] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!nome || !email || !titulo || !mensagem) {
      alert("Por favor, preencha todos os campos.");
      return;
    }

    const emailBody = `Olá, meu nome é ${nome}, gostaria de saber sobre ${titulo}, minha dúvida é a seguinte: ${mensagem}, caso queira me contatar novamente meu e-mail é ${email}`;
    const mailtoLink = `mailto:contato.dinamicadaface@hotmail.com?subject=${encodeURIComponent(
      titulo
    )}&body=${encodeURIComponent(emailBody)}`;

    window.location.href = mailtoLink;
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:contato.dinamicadaface@hotmail.com";
  };

  const handleWhatsAppClick = () => {
    window.open("https://contate.me/institutodinamicadaface", "_blank");
  };

  return (
    <React.Fragment>
      <header className={Classes.Connect_Header}>
        <p className={Classes.Connect_Header_Title}>Conecte-se</p>
        <p className={Classes.Connect_Header_SubTitle}>
          Preencha os campos abaixo:
        </p>
      </header>
      <div className={Classes.Connect_Box}>
        <form className={Classes.ConnectForm} onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="Nome"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <input
            type="text"
            placeholder="Seu e-mail"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="Título"
            value={titulo}
            onChange={(e) => setTitulo(e.target.value)}
          />
          <input
            type="text"
            placeholder="Mensagem"
            value={mensagem}
            onChange={(e) => setMensagem(e.target.value)}
          />
          <button type="submit">Enviar</button>
        </form>

        <div className={Classes.ConnectIcons}>
          <img src={wpp} onClick={handleWhatsAppClick} />

          <a href="#" onClick={handleEmailClick}>
            <img src={emailIcon} />
          </a>

          <a
            href="https://instagram.com/dinamicadaface?igshid=MmVlMjlkMTBhMg%3D%3D&utm_source=qr"
            target="_blank"
          >
            <img src={insta} />
          </a>
          <img src={linkedin} />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Connect;
