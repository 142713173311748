const INITIAL_STATE = {
    Page : "Home"
}



export default function PageState(state=INITIAL_STATE,action){
    if(action.type === 'CHANGE_PAGE_STATE'){
        return {Page:action.Page}
    }    
    return state
}