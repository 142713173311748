import React, { useEffect, useState } from "react";
import Classes from './index.module.css'


function TreatmentBox(props){
    const [Image,setImage] = useState('')
    useEffect(()=>{
        setImage(props.image)
    },[props.image])
    return(
        <React.Fragment>
            <div className={Classes.TreatmentBox}>
                
            <div className={Classes.Gallery_Images}>


            
                

                        <img  src={Image} alt={props.alt}/>
                
               
                    
            </div>


                <section className={Classes.TreatmentBox_Content}>
                
                <div className={Classes.TreatmentBox_Content_Name}>
                {props.Name}
                </div>


               
                
               
                
                <div className={Classes.TreatmentBox_Content_Text}>
                {props.content}
                </div>

                </section>

            </div>
        </React.Fragment>
    )
}


export default TreatmentBox