import React, { useEffect } from "react";
import Classes from "./index.module.css";

import { Link } from "react-router-dom";
import FirstBox from "../../assets/Board/carie.jpeg";
import SecondBox from "../../assets/Board/respirador.jpeg";
import ThirdBox from "../../assets/Home/services/BlogImg.jpg";
import ForthBox from "../../assets/Home/services/Skincare2.jpg";
import FifthImage from "../../assets/Home/services/teethcare2.jpg";
import SixBox from "../../assets/Home/services/floss.jpg";
import ProfileBox from "../../components/Team/ProfileBox";
import BoardBox from "../../components/Board/BoardBox";
import { useParams } from "react-router-dom";

function Board() {
  const { topic } = useParams();

  useEffect(() => {
    if (topic) {
      const element = document.getElementById(topic);
      console.log("Element:", element); // Para depuração
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      } // Ajuste o tempo de acordo com a necessidade
    }
  }, [topic]);

  const User1 = (
    <>
      <p>
        A cárie é uma das doenças mais comuns no Brasil, mas muitas pessoas nem
        imaginam que sofrem deste mal. Ela é uma deterioração do dente que está
        diretamente relacionada ao estilo de vida do indivíduo, ou seja, ao que
        come, como cuida dos dentes e se tem acesso à água fluoretada. A higiene
        bucal correta é uma das melhores maneiras de prevenir a doença.
      </p>

      <p>
        {" "}
        Atualmente, o consumo elevado de açúcar é preocupante, pois ele está
        presente em bolachas, refrigerantes, doces, balas, chicletes, sorvetes,
        etc. Por isso, é imprescindível escovar corretamente os dentes após as
        refeições, massageando a gengiva com creme dental que contenha flúor em
        sua composição e usar fio dental, que remove os restos de alimentos e a
        placa bacteriana nos locais aonde a escova não chega.{" "}
      </p>
      <p>
        Além disso, visitar o dentista periodicamente é uma maneira de evitar
        diversos problemas bucais. Isto porque muitos adultos pensam que apenas
        as crianças estão suscetíveis à cárie e não dão a devida atenção à
        importância de se manter uma boa higiene bucal ao longo de toda a vida.
        À medida que ficamos mais velhos, a cárie em volta das restaurações e na
        raiz dos dentes se tornam mais comuns, podendo agravar outras doenças,
        como diabetes e problemas cardíacos.
      </p>
    </>
  );

  const User2 = (
    <>
      <p>
        A respiração oral pode começar com a amamentação incorreta, hábitos
        bucais como o uso da chupeta, mamadeira e dedo prolongados. Muitas
        crianças podem apresentar ronco, bruxismo, sono agitado e a enurese
        noturna. Sim!! Isso mesmo! Crianças maiores de 4 anos podem fazer xixi
        na cama, por serem respiradoras bucais, terem sono agitado e assim
        apresentar um descontrole dos hormônios que regulam a produção de urina.
        Atenção que o fator emocional também pode estar presente. Quais os
        efeitos e consequências da respiração oral?
      </p>
      <ul>
        <li>Olheira, olhar cansado.</li>
        <li>Atresia maxilar (céu da boca pequeno).</li>
        <li>Dentes para fora, gengiva inchada.</li>
        <li>Falta de selamento labial, lábios secos.</li>
        <li>Postura de língua baixa.</li>
        <li>Nariz obstruído.</li>
        <li>Bruxismo ou ranger dos dentes.</li>
        <li>Ronco e apnéia do sono.</li>
        <li>
          Falta de qualidade do sono, agitação, cansaço frequente e baixo
          rendimento escolar.
        </li>
        <li>Dificuldade para comer, mastigar e falar.</li>
        <li>Maior potencial para cárie, gengivite e mau hálito.</li>
      </ul>
    </>
  );

  const User3 = (
    <>
      <p>
        Trata-se de um procedimento seguro, não invasivo e com ótimos
        resultados. Por isso a toxina botulínica, mais conhecida pelo nome da
        sua marca mais famosa, o Botox®, tornou-se tão popular.
      </p>
      <p>
        É responsável em diminuir a contração dos músculos da face, amenizando
        rugas de expressão como pés de galinha, linhas da testa e rugas no
        centro dos olhos.
      </p>
      <p>
        A toxina botulínica pode levantar as sobrancelhas e passar um ar de face
        rejuvenescida e descansada, aliviando as expressões do rosto.
      </p>
      <p>
        Também pode ser utilizada para saúde odontológica no tratamento de
        bruxismo, por diminuir a força de contração do masseter, ou para o
        tratamento do sorriso gengival.
      </p>
      <p>
        Com a toxina botulínica, podemos trabalhar no contorno do rosto,
        flacidez do pescoço, além de dar aquele lifting no nariz através da
        aplicação de um ponto na base do nariz.
      </p>
      <p>
        O procedimento é realizado em consultório e dura cerca de 15 minutos. O
        resultado começa a aparecer em 3 dias, sendo o pico de ação de 15 a 21
        dias. Se necessário, o paciente deve voltar ao consultório para uma
        correção. Os resultados duram entre 4 e 6 meses, quando o procedimento
        pode ser realizado novamente.
      </p>
    </>
  );

  const User4 = (
    <>
      <p>
        Os cuidados com a pele após um procedimento facial são essenciais para
        promover a recuperação adequada e garantir resultados satisfatórios.
        Após o procedimento, é importante seguir as orientações do profissional
        para otimizar a cicatrização e minimizar possíveis complicações.
      </p>
      <p>
        Uma das principais recomendações é evitar exposição solar direta,
        especialmente nas primeiras semanas após o procedimento. A pele estará
        mais sensível e propensa a queimaduras solares, além de ser importante
        proteger as áreas tratadas da hiperpigmentação. Utilizar protetor solar
        com fator de proteção adequado e reaplicá-lo regularmente é fundamental.
      </p>
      <p>
        Manter a pele hidratada é outra medida importante. O profissional poderá
        indicar produtos específicos para esse fim, como cremes ou loções
        hidratantes suaves, que ajudam a manter a pele saudável e acelerar o
        processo de recuperação. É essencial evitar o uso de produtos irritantes
        ou agressivos, como ácidos ou esfoliantes, que podem causar desconforto
        e prejudicar a cicatrização.
      </p>
      <p>
        É recomendado evitar o uso de maquiagem pesada nas áreas tratadas,
        especialmente nos primeiros dias após o procedimento. Dar à pele tempo
        para se recuperar e respirar livremente ajuda a prevenir irritações e
        possíveis infecções. Caso seja necessário utilizar maquiagem, opte por
        produtos leves e hipoalergênicos, evitando qualquer área sensível ou com
        lesões.
      </p>
    </>
  );

  const User5 = (
    <>
      <p>
        Para combater o mau hálito, é essencial adotar hábitos de higiene bucal
        adequados e manter um estilo de vida saudável. Além disso, existem
        algumas dicas simples que podem ajudar a eliminar o mau hálito e manter
        um hálito fresco. Confira a seguir:
      </p>
      <ul>
        <li>
          Mantenha uma boa higiene bucal, escovando os dentes pelo menos duas
          vezes ao dia e usando fio dental regularmente. A escovação adequada
          remove os resíduos de alimentos e a placa bacteriana, que podem causar
          o mau hálito.
        </li>
        <li>
          Não se esqueça de limpar a língua também. A língua pode acumular
          bactérias e restos de comida, contribuindo para o mau hálito. Utilize
          um raspador de língua ou escove-a suavemente com a escova de dentes.
        </li>
        <li>
          Mantenha-se hidratado, bebendo água com frequência. A boca seca pode
          contribuir para o mau hálito, pois a saliva tem um papel importante na
          neutralização de odores e na remoção de resíduos bacterianos. Beber
          água ajuda a manter a boca hidratada e estimula a produção de saliva.
        </li>
        <li>
          Evite alimentos com odores fortes, como alho e cebola, que podem
          causar o mau hálito. Esses alimentos contêm compostos voláteis que são
          absorvidos pela corrente sanguínea e eliminados pelos pulmões,
          afetando o odor da respiração.
        </li>
        <li>
          Considere fazer consultas regulares ao dentista. O mau hálito pode ser
          causado por problemas dentários, como cáries, doenças periodontais ou
          infecções na boca. O dentista poderá identificar e tratar essas
          condições, ajudando a combater o mau hálito.
        </li>
        <li>
          Evite o tabaco e o consumo excessivo de álcool, pois esses hábitos
          podem contribuir para o mau hálito. O tabaco deixa resíduos nos
          dentes, língua e mucosas, enquanto o álcool pode ressecar a boca e
          favorecer o crescimento bacteriano.
        </li>
        <li>
          Se você utiliza próteses dentárias, como dentaduras ou aparelhos
          ortodônticos, siga corretamente as instruções de higiene e limpeza
          fornecidas pelo dentista. Próteses mal higienizadas podem acumular
          bactérias e causar mau hálito.
        </li>
        <li>
          Em casos persistentes de mau hálito, mesmo com uma boa higiene bucal,
          é recomendado buscar orientação médica. Algumas condições de saúde,
          como infecções respiratórias, refluxo gastroesofágico e problemas no
          fígado, podem estar associadas ao mau hálito e exigir tratamento
          específico.
        </li>
      </ul>
    </>
  );

  const User6 = (
    <>
      <p>
        O uso do fio dental é fundamental para combater o mau hálito e manter
        uma boa saúde bucal. A escovação sozinha não é suficiente, pois não
        alcança as áreas entre os dentes onde os resíduos de alimentos e a placa
        bacteriana podem se acumular.
      </p>
      <p>
        O fio dental remove esses resíduos, prevenindo cáries, doenças gengivais
        e o mau hálito. É importante passar o fio cuidadosamente entre os
        dentes, removendo os restos de comida e as bactérias responsáveis pelo
        odor desagradável.
      </p>
      <p>
        Além disso, o uso regular do fio dental ajuda a prevenir a formação de
        placa bacteriana, gengivite e outras doenças gengivais.
      </p>
      <p>
        Lembre-se de incorporar o uso do fio dental na sua rotina diária de
        higiene bucal, junto com a escovação e o enxaguante bucal, e consulte o
        dentista regularmente para obter orientações personalizadas.
      </p>
    </>
  );
  return (
    <React.Fragment>
      <header className={Classes.Board_Header}>
        <p className={Classes.Board_Header_Title}>Mural</p>
      </header>
      <div className={Classes.Board_Box}>
        <BoardBox
          id="caries"
          image={FirstBox}
          Name={<p>Você já sabe tudo sobre a cárie?</p>}
          content={User1}
        />

        <BoardBox
          id="respiracao-oral"
          image={SecondBox}
          Name={
            <p>
              Você sabia que respirar pela boca prejudica o sono da criança?
            </p>
          }
          content={User2}
        />

        <BoardBox
          id="toxina-botulinica"
          image={ThirdBox}
          Name={<p>Saiba os benefícios da toxina botulínica</p>}
          content={User3}
        />

        <BoardBox
          id="procedimento-facial"
          image={ForthBox}
          Name={<p>Cuidados com a pele depois de um procedimento facial</p>}
          content={User4}
        />

        <BoardBox
          id="mau-halito"
          image={FifthImage}
          Name={<p>8 dicas de como combater o mau hálito</p>}
          content={User5}
        />

        <BoardBox
          id="fio-dental"
          image={SixBox}
          Name={<p>Usar fio dental é fundamental</p>}
          content={User6}
        />

        {/*
                <ProfileBox image={SecondBox} Name={<p>Dr. Mauricio <br /> Dal Moro</p>} CRO={'97.401'}/>

                <ProfileBox image={FirstBox} Name={<p>Dra. Andrea</p>} CRO={'00.000'}/>
    */}

        <Link to="/Conectar">
          <section className={Classes.AppointmentButton}>
            <p>Agende sua avaliação</p>
          </section>
        </Link>
      </div>
    </React.Fragment>
  );
}

export default Board;
