import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import Header from "./components/Global/Header/Header";
import Home from "./pages/Home/Home";
import Treatments from "./pages/Treatments";
import Institute from "./pages/Institute";
import Team from "./pages/Team";
import Footer from "./components/Global/Footer";
import TreatmentPage from "./pages/TreatmentPage";
import Connect from "./pages/Connect";
import Board from "./pages/Board";
import wpp from "./assets/Global/wpp2.svg";
import "./App.css"; // Importação da folha de estilo

function App() {
  return (
    <Provider store={store}>
      <Router>
        <React.Fragment>
          <Header />

          <div className="App">
            <a
              href="https://contate.me/institutodinamicadaface"
              className={"Wpp_ICon2"}
            >
              <img src={wpp} className={"Wpp_ICon2"}></img>
            </a>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Tratamentos" element={<Treatments />} />
              <Route path="/Instituto" element={<Institute />} />
              <Route path="/Equipe" element={<Team />} />
              <Route
                path="/Tratamento/:treatment"
                element={<TreatmentPage />}
              />
              <Route path="/Conectar" element={<Connect />} />
              <Route path="/Blog/:topic" element={<Board />} />
              <Route path="/Blog/" element={<Board />} />
            </Routes>
            <Footer />
          </div>
        </React.Fragment>
      </Router>
    </Provider>
  );
}

export default App;
