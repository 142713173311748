import React from "react";
import Classes from "./index.module.css";

function BoardBox(props) {
  return (
    <React.Fragment>
      <div id={props.id} className={Classes.BoardBox}>
        <div className={Classes.Gallery_Images}>
          <img alt="" src={props.image} />
        </div>

        <section className={Classes.BoardBox_Content}>
          <div className={Classes.BoardBox_Content_Name}>{props.Name}</div>

          <div className={Classes.BoardBox_Content_Text}>{props.content}</div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default BoardBox;
