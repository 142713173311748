import React from "react";
import Classes from "./Header.module.css";

import LogoBranco from "../../../assets/Header/LogoBranco.png";
import RedesSociais from "../../../assets/Header/RedesSociais.png";
import Lupa from "../../../assets/Header/Lupa.png";
import leaf from "../../../assets/Header/HeaderBackground.png";

import { Link } from "react-router-dom";

function Header() {
  return (
    <React.Fragment>
      <div className={Classes.Header_Container}>
        {/*Logo*/}
        <section className={Classes.Logo}>
          <img alt="" src={LogoBranco} />
        </section>
        {/*Fim do Logo*/}

        {/*Barra de menu*/}
        <section className={Classes.MenuBar}>
          <ul>
            <li style={{ borderRight: "0.1vw solid #ffffff" }}>
              <Link to="/">HOME</Link>
            </li>
            <li style={{ borderRight: "0.1vw solid #ffffff" }}>
              <Link to="/Instituto">O INSTITUTO</Link>
            </li>
            <li style={{ borderRight: "0.1vw solid #ffffff" }}>
              <Link to="/Tratamentos">TRATAMENTOS</Link>
            </li>
            <li style={{ borderRight: "0.1vw solid #ffffff" }}>
              <Link to="/Equipe">EQUIPE</Link>
            </li>
            <li style={{ borderRight: "0.1vw solid #ffffff" }}>
              <Link to="/Blog/">MURAL</Link>
            </li>
            <li>
              <Link to="/Conectar">CONECTE-SE</Link>
            </li>
          </ul>
        </section>
        {/*Fim da Barra de menu*/}

        {/*Links*/}
        <section className={Classes.Links}>
          <img alt="" src={RedesSociais} />
          <img className={Classes.Lupa} alt="" src={Lupa} />
        </section>
        {/*Fim do Links*/}

        <img className={Classes.LeafBackground} src={leaf} />
      </div>
    </React.Fragment>
  );
}

export default Header;
